import axios from 'axios';

const API_URL = '/api/users';

export default {
  // 유저 정보 가져오기
  async fetchUserProfile(token) {
    return axios.get(`${API_URL}/profile`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  // 유저 정보 업데이트
  async updateUserProfile(userId, userProfile, token) {
    return axios.patch(`${API_URL}/users/${userId}/profile`, userProfile, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
};
