import axios from 'axios';

const API_BASE_URL = '/api/users';

export default {
  getProfile(token) {
    return axios.get(`${API_BASE_URL}/profile`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  }
};

