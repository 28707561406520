// store/modules/profile.js
import ProfileService from '@/services/profileService';

const state = {
  userProfile: {
    id: null,
    nickname: '',
    name: '',
    address: '',
    phone_number: '',
    age: null,
    gender: '',
    weight: null,
    height: null,
    family_history: '',
    medical_conditions: []
  },
  isLoading: true
};

const mutations = {
  SET_USER_PROFILE(state, userProfile) {
    state.userProfile = userProfile;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  }
};

const actions = {
  async fetchUserProfile({ commit }) {
    commit('SET_LOADING', true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }
      const response = await ProfileService.getProfile(token);
      commit('SET_USER_PROFILE', response.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    } finally {
      commit('SET_LOADING', false);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
