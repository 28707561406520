// // store/modules/user.js
// import UserService from '@/services/userService';

// const state = {
//   userProfile: {
//     id: null,
//     nickname: '',
//     name: '',
//     address: '',
//     phone_number: '',
//     age: null,
//     gender: '',
//     weight: null,
//     height: null,
//     family_history: '',
//     medical_conditions: []
//   },
//   isLoading: true
// };

// const mutations = {
//   SET_USER_PROFILE(state, userProfile) {
//     state.userProfile = userProfile;
//   },
//   SET_LOADING(state, isLoading) {
//     state.isLoading = isLoading;
//   }
// };

// const actions = {
//   async fetchUserProfile({ commit }) {
//     commit('SET_LOADING', true);
//     try {
//       const token = localStorage.getItem('token');
//       if (!token) {
//         console.error('No token found');
//         return;
//       }
//       const response = await UserService.fetchUserProfile(token);
//       commit('SET_USER_PROFILE', response.data);
//     } catch (error) {
//       console.error('Error fetching user profile:', error);
//     } finally {
//       commit('SET_LOADING', false);
//     }
//   },
//   async updateUserProfile({ state }) {
//     try {
//       const token = localStorage.getItem('token');
//       if (!token) {
//         throw new Error('No token found');
//       }
//       const userId = state.userProfile.id;
//       const response = await UserService.updateUserProfile(userId, state.userProfile, token);
//       console.log('Profile updated successfully:', response.data);
//     } catch (error) {
//       console.error('Error updating profile:', error);
//       throw error;
//     }
//   }
// };

// export default {
//   namespaced: true,
//   state,
//   mutations,
//   actions
// };


// store/modules/user.js
// store/modules/user.js
// store/modules/user.js
import UserService from '@/services/userService';
import MealService from '@/services/MealService';

const state = {
  userProfile: {
    id: null,
    nickname: '',
    name: '',
    address: '',
    phone_number: '',
    age: null,
    gender: '',
    weight: null,
    height: null,
    family_history: '',
    medical_conditions: []
  },
  mealRecords: [],
  mealRecordsByDate: [],
  isLoading: true
};

const mutations = {
  SET_USER_PROFILE(state, userProfile) {
    state.userProfile = userProfile;
  },
  SET_MEAL_RECORDS(state, mealRecords) {
    state.mealRecords = mealRecords;
  },
  SET_MEAL_RECORDS_BY_DATE(state, mealRecordsByDate) {
    state.mealRecordsByDate = mealRecordsByDate;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  }
};

const actions = {
  async fetchUserProfile({ commit }) {
    commit('SET_LOADING', true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }
      const response = await UserService.fetchUserProfile(token);
      commit('SET_USER_PROFILE', response.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async fetchMealRecords({ commit }, userId) {
    commit('SET_LOADING', true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }
      const response = await MealService.fetchMealsByUser(userId, token);
      commit('SET_MEAL_RECORDS', response);
    } catch (error) {
      console.error('Error fetching meal records:', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async fetchMealRecordsByDate({ commit }, { userId, date }) {
    commit('SET_LOADING', true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }
      const response = await MealService.fetchMealsByUserAndDate(userId, date, token);
      commit('SET_MEAL_RECORDS_BY_DATE', response); // response.data가 아닌 response로 변경
    } catch (error) {
      console.error('Error fetching meal records by date:', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateUserProfile({ state }) {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      const userId = state.userProfile.id;
      const response = await UserService.updateUserProfile(userId, state.userProfile, token);
      console.log('Profile updated successfully:', response.data);
    } catch (error) {
      console.error('Error updating profile:', error);
      throw error;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
