import { createWebHashHistory, createRouter } from 'vue-router';
const MainP = () => import('@/views/MainP.vue');
const InfoP = () => import('@/views/InfoP.vue');
const ProfileP = () => import('@/views/ProfileP.vue');
const SettingP = () => import('@/views/SettingP.vue');
const EditProfile = () => import('@/views/EditProfile.vue');
const FoodP = () => import('@/views/FoodP.vue');
const FoodIngredientsP = () => import('@/views/buttons/FoodIngredientsP.vue');
const eatNoteP = () => import('@/views/buttons/eatNoteP.vue');
const LoginComponent = () => import('@/views/LoginComponent.vue');
const RegisterComponent = () => import('@/views/RegisterComponent.vue');
const NotFound = () => import('@/components/common/NotFound.vue');
const AgreeTerms = () => import('@/components/AgreeTerms.vue');
const UserMealRecord = () => import('@/views/meal/UserMealRecord.vue');
const UserMeal = () => import('@/views/meal/UserMeal.vue');
const YesterdayEvaluation = () => import('@/views/meal/YesterdayEvaluation.vue');
const WeekEvaluation = () => import('@/views/meal/WeekEvaluation.vue');

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: LoginComponent
  },
  {
    path: '/terms-agreement',
    name: 'AgreeTerms',
    component: AgreeTerms
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterComponent,
    meta: { requiresTermsAgreement: true }
  },
  {
    path: '/info',
    name: 'Info',
    component: InfoP,
    meta: { requiresAuth: true }
  },
  {
    path: '/main',
    name: 'Main',
    component: MainP
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileP
  },
  {
    path: '/setting',
    name: 'Setting',
    component: SettingP
  },
  {
    path: '/edit',
    name: 'EditProfile',
    component: EditProfile,
    props: true
  },
  {
    path: '/food',
    name: 'Food',
    component: FoodP
  },
  {
    path: '/foodIngredients',
    name: 'FoodIngredients',
    component: FoodIngredientsP
  },
  {
    path: '/eatnote',
    name: 'EatNote',
    component: eatNoteP
  },
  {
    path: '/user/meal/:userId',
    name: 'UserMeal',
    component: UserMeal,
    props: true
  },
  {
    path: '/user/meal/:userId/record',
    name: 'UserMealRecord',
    component: UserMealRecord,
    props: true
  },


  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/user/evaluation/yesterday/:userId',
    name: 'YesterdayEvaluation',
    component: YesterdayEvaluation,
    props: true
  },
  {
    path: '/user/evaluation/week/:userId',
    name: 'WeekEvaluation',
    component: WeekEvaluation,
    props: true
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const agreedTerms = JSON.parse(localStorage.getItem('agreedTerms') || '[]');

  if (to.meta.requiresAuth && !isLoggedIn) {
    next({ name: 'Welcome', query: { redirect: to.fullPath } });
  } else if (to.meta.requiresTermsAgreement && (!agreedTerms || agreedTerms.length === 0)) {
    alert('모든 필수 약관에 동의해야 회원가입을 진행할 수 있습니다.');
    next({ name: 'AgreeTerms' });
  } else {
    next();
  }
});

export default router;
