import authService from '@/services/authService'; // authService를 임포트합니다.

export default {
  namespaced: true, // 네임스페이스를 활성화하여 모듈을 독립적으로 만듭니다.
  state: { // 스토어의 상태를 정의합니다.
    accessToken: null, // 액세스 토큰
    isLoggedIn: false, // 로그인 상태
    isLoading: true, // 로딩 상태
    isOffline: !navigator.onLine, // 온라인 상태
  },
  mutations: { // 상태를 변화시키는 메소드를 정의합니다.
    SET_LOGIN_STATUS(state, status) {
      state.isLoggedIn = status; // 로그인 상태 설정
    },
    SET_LOADING(state, status) {
      state.isLoading = status; // 로딩 상태 설정
    },
    SET_OFFLINE(state, status) {
      state.isOffline = status; // 온라인/오프라인 상태 설정
    },
    SET_TOKEN(state, token) {
      state.accessToken = token; // 액세스 토큰 설정
      state.isLoggedIn = !!token; // 토큰 유무에 따른 로그인 상태 설정
  },
  },
  actions: { // 비동기 로직을 처리합니다.
    async login({ commit }, { nickname, password }) {
      commit('SET_LOADING', true); // 로딩 시작
      try {
        const response = await authService.login(nickname, password); // 로그인 시도
        commit('SET_TOKEN', response.access_token); // 토큰 저장
        localStorage.setItem('token', response.access_token); // 로컬 스토리지에 토큰 저장
        localStorage.setItem('isLoggedIn', true); // 로컬 스토리지에 로그인 상태 저장
        commit('SET_LOADING', false); // 로딩 종료
        commit('SET_LOGIN_STATUS', true); // 로그인 상태를 true로 설정
        return { isComplete: response.is_complete, redirectPath: response.is_complete ? '/main' : '/info' };
        // 로그인 완료 상태에 따라 리다이렉트 경로 반환
      } catch (error) {
        console.error('Login error:', error); // 로그인 에러 로깅
        alert("Login failed: " + (error.response && error.response.data && error.response.data.detail ? error.response.data.detail : "Unknown error"));
        // 로그인 실패 알림
        commit('SET_LOADING', false); // 로딩 종료
        commit('SET_LOGIN_STATUS', false); // 로그인 상태를 false로 설정
        throw error; // 에러를 상위로 전달
      }
    },
    checkNetwork({ commit }) {
      commit('SET_OFFLINE', !navigator.onLine); // 네트워크 상태 확인 후 설정
    },
  },
};
