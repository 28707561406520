import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // Ensure this points to your store directory correctly
import Notifications from '@kyvg/vue3-notification';
import './global.css';

createApp(App)
  .use(router)
  .use(store) // Use the Vuex store here
  .use(Notifications)
  .mount('#app');
