import { createStore } from 'vuex'; // Vuex에서 createStore 함수를 임포트합니다.
import auth from './modules/auth'; // auth 모듈을 임포트합니다.
import user from './modules/user';
import profile from './modules/profile';


const store = createStore({
    modules: {
      auth, // 스토어에 auth 모듈을 등록합니다.
      user,
      profile
    },
});

export default store; // 설정된 스토어를 다른 파일에서 사용할 수 있도록 내보냅니다.
