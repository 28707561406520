import axios from 'axios';

// API 엔드포인트 주소를 정의합니다.
const API_ENDPOINT = '/api/users/token';

export default {
    // 비동기 로그인 함수를 정의합니다. nickname과 password를 인자로 받습니다.
    async login(username, password) {
        // URLSearchParams 객체를 사용하여 요청 파라미터를 설정합니다.
        const params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);
        try {
            // axios.post를 사용하여 로그인 요청을 보냅니다.
            const response = await axios.post(API_ENDPOINT, params, {
                headers: {
                    // 요청 헤더에 콘텐츠 타입과 로컬 스토리지에서 가져온 토큰을 추가합니다.
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            });
            
            // 서버로부터 받은 응답 데이터를 반환합니다.
            return response.data;
        } catch (error) {
            // 에러가 발생하면 콘솔에 로그인 실패 메시지를 출력하고 에러를 던집니다.
            console.error('Login failed:', error.response);
            throw error;
        }
    }
};
