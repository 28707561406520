import axios from 'axios';

const API_URL = 'https://sanare.shop/api/meals'; // 백엔드 API 경로

export default {
  // 모든 식사 기록을 가져오는 함수
  async fetchMealRecords(token) {
    try {
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching meal records:', error);
      throw error;
    }
  },

  async createMeal(mealData) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(API_URL, mealData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error creating meal record:', error);
      throw error;
    }
  },
  async fetchMealsByUser(userId, token) {
    try {
      const response = await axios.get(`${API_URL}/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching meals by user:', error);
      throw error;
    }
  },

  async fetchMealsByUserAndDate(userId, date, token) {
    try {
      const response = await axios.get(`${API_URL}/user/${userId}/date/${date}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching meals by user and date:', error);
      throw error;
    }
  }
};
